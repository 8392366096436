<template>
  <div class="wrap">
    <div class="icon"><img src="@/assets/error_book/icon-定位.png"
           alt=""
           title="还原大小"
           @click="resizeMap"><span>（提示：点击节点可查看对应信息）</span></div>
    <div id="jsmind_container"
         ref="container"></div>
  </div>

</template>

<script>
import 'jsmind/style/jsmind.css'
import '@/statics/jsmind.css'
import jsMind from 'jsmind/js/jsmind.js'
require('jsmind/js/jsmind.draggable.js')
require('jsmind/js/jsmind.screenshot.js')

export default {
  data () {
    return {
      thisNode: undefined,
      editor: undefined,
      originalScrollLeft: '',
      originalScrollTop: '',

      floatData: {},
      nodeId: '',

      mind: {
        /* 元数据，定义思维导图的名称、作者、版本等信息 */
        meta: {
          name: '思维导图',
          author: '',
          version: ''
        },
        /* 数据格式声明 */
        format: 'node_tree',
        /* 数据内容 */

        data: {}
      },
      options: {
        container: 'jsmind_container', // [必选] 容器的ID
        editable: true, // [可选] 是否启用编辑

        theme: 'greensea', // [可选] 主题
        view: {
          engine: 'canvas', // 思维导图各节点之间线条的绘制引擎
          hmargin: 80, // 思维导图距容器外框的最小水平距离
          vmargin: 50, // 思维导图距容器外框的最小垂直距离
          line_width: 3, // 思维导图线条的粗细
          line_color: '#2b97f1' // 思维导图线条的颜色
        },
        layout: {
          hspace: 60, // 节点之间的水平间距
          vspace: 40, // 节点之间的垂直间距
          pspace: 0 // 节点与连接线之间的水平间距（用于容纳节点收缩/展开控制器）
        },
        shortcut: {
          enable: false // 是否启用快捷键 默认为true
        }
      },
    }
  },
  mounted () {
    this.jm = new jsMind(this.options);//初始化盒子内容
  },

  watch: {

    thisNode: {
      handler () {
        // this.changeInfo()
        if (this.thisNode) {
          this.nodeId = this.thisNode.id
          // this.$emit('setFloatData')
          this.$parent.setFloatData(this.nodeId, this.thisNode.data.parentId, this.thisNode.data.type)
          // console.log(this.floatData)
        }
      },
      deep: true
    }
  },
  props: {


    // compactData: {
    //   type: Object,
    //   default: () => {
    //     return {}
    //   }
    // }

  },
  methods: {
    init (data) {

      this.mind.data = data
      this.jm.show(this.mind);//显示
      // this.getNode()

      this.mouseDrag()
      this.mouseWheel()
      this.setZoom()
      this.e_editor = this.jm.view.e_editor
      this.getNode()
      const el = document.querySelector('.jsmind-inner')
      this.originalScrollLeft = el.scrollLeft
      this.originalScrollTop = el.scrollTop
      // console.log(this.originalScrollLeft)
      // 遍历节点数据并根据节点的某个值动态设置节点样式
      this.setNodeStyles(this.mind.data.children);
    },
    setNodeStyles (nodes) {

      nodes.forEach(node => {

        if (node.children && node.children.length > 0) {
          this.setNodeStyles(node.children);
        }


        if (Number(node.score) > 80) {
          this.jm.set_node_color(node.id, '#a5d6a7');
        } else if (60 < Number(node.score) && Number(node.score) < 80) {
     
          this.jm.set_node_color(node.id, '#fff59d');
        } else if (Number(node.score) < 60) {
          this.jm.set_node_color(node.id, '#ef9a9a');
        }
      });
    },
    getNode () {
      // jsmind 添加自定义点击事件
      this.jm.view.add_event(this.editor, 'click', (e) => {

        if (this.jm.get_selected_node()) {
          this.thisNode = this.jm.get_selected_node()
        }
      })
    },
    // 切换题目时清空选中数据
    cancelSelected () {
      this.jm.select_clear()
      this.thisNode = undefined
    },

    // 鼠标滚轮放大缩小
    mouseWheel () {
      if (document.addEventListener) {
        // document.addEventListener('DomMouseScroll', this.scrollFunc, false)
        this.$refs.container.addEventListener('DOMMouseScroll', this.scrollFunc, false)
      }
      this.$refs.container.onmousewheel = this.scrollFunc
    },
    // 滚轮缩放
    scrollFunc (e) {
      e.preventDefault()
      e = e || window.event
      if (e.wheelDelta) {
        if (e.wheelDelta > 0) {
          this.jm.view.zoomIn()
        } else {
          this.jm.view.zoomOut()
        }
      } else if (e.detail) {
        if (e.detail > 0) {
          this.jm.view.zoomOut()
        } else {
          this.jm.view.zoomIn()
        }
      }
      this.jm.view.resize()

    },
    // 鼠标拖拽
    mouseDrag () {
      // 里层
      const el = document.querySelector('.jsmind-inner')
      // 选中节点
      let selected

      el.onmousedown = (ev) => {
        // 选中节点
        selected = this.jm.get_selected_node()
        // 标识 是否拖拽节点 避免冲突
        this.dragNodeFlag = !!selected

        const disX = ev.clientX
        const disY = ev.clientY
        const originalScrollLeft = el.scrollLeft
        const originalScrollTop = el.scrollTop
        const originalScrollBehavior = el.style['scroll-behavior']
        const originalPointerEvents = el.style['pointer-events']
        // auto: 默认值，表示滚动框立即滚动到指定位置。
        el.style['scroll-behavior'] = 'auto'
        // 鼠标移动事件是监听的整个document，这样可以使鼠标能够在元素外部移动的时候也能实现拖动
        document.onmousemove = (ev) => {
          if (this.dragNodeFlag) return
          this.drag = false
          ev.preventDefault()
          // 计算拖拽的偏移距离
          const distanceX = ev.clientX - disX
          const distanceY = ev.clientY - disY

          el.scrollTo(originalScrollLeft - distanceX, originalScrollTop - distanceY)

          // 在鼠标拖动的时候将点击事件屏蔽掉
          el.style['pointer-events'] = 'none'
          el.style.cursor = 'grabbing'
        }
        document.onmouseup = () => {
          if (!this.dragNodeFlag) {
            el.style['scroll-behavior'] = originalScrollBehavior
            el.style['pointer-events'] = originalPointerEvents
            el.style.cursor = 'grab'
            if (this.thisNode && this.thisNode.id && this.thisNode.id != 'topic_root' && this.thisNode.id != 'knowledge_root' && this.thisNode.id != 'checkpoint_root') {
              this.jm.select_node(this.thisNode.id)
            }
          }
          document.onmousemove = document.onmouseup = null
        }
      }
    },
    // 重写设计层级方法
    setZoom () {
      this.jm.view.setZoom = (zoom) => {
        if ((zoom < this.jm.view.minZoom) || (zoom > this.jm.view.maxZoom)) {
          return false
        }
        this.jm.view.actualZoom = zoom;
        for (var i = 0; i < this.jm.view.e_panel.children.length; i++) {
          // this.jm.view.e_panel.children[i].style.zoom = zoom
          this.jm.view.e_panel.children[i].style.transformOrigin = '0px 0px'
          this.jm.view.e_panel.children[i].style.transform = 'scale(' + zoom + ')';
        }
        return true
      }
    },
    // 还原大小位置
    resizeMap () {
      // console.log(this.jm.view)
      for (var i = 0; i < this.jm.view.e_panel.children.length; i++) {
        this.jm.view.e_panel.children[i].style.transformOrigin = '0px 0px'
        this.jm.view.e_panel.children[i].style.transform = 'scale(' + 1 + ')';
        // this.jm.view.e_panel.children[i].style.zoom = 1;
      }
      this.jm.view.actualZoom = 1;
      const el = document.querySelector('.jsmind-inner')
      el.scrollTo(this.originalScrollLeft, el.scrollTop)
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  height: 100%;
}
#jsmind_container {
  width: 100%;
  height: calc(100% - 71rem);
}
.icon {
  padding: 24rem;
  span {
    color: #aaa;
    font-size: 16rem;
    line-height: 24px;
    padding-left: 16rem;
  }
  img {
    width: 24px;
    height: 24px;
    // width: 100%;
    cursor: pointer;
  }
}
</style>
